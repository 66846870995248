const WELCOME_PAGE = 'welcome page';
const LONG_ANSWER = 'long answer';
const SHORT_ANSWER = 'short answer';
const DROP_DOWN_MENU = 'drop down menu';
const SIMPLE_SELECTION = 'simple selection';
const SATISFACTION = 'satisfaction';
const SELECT_PAGE = 'select page';
const MULTIPLE_SELECTION = 'multiple selection';
const NUMERIC_ANSWER = 'numeric answer';
const DECIMAL_ANSWER = 'decimal answer';
const END_PAGE = 'end page';
const YES_NO_QUESTION = 'yes/no question';
const DATE = 'date';
const CONTENT = 'content';
const NPS_QUESTION = 'nps question';
const MULTIPLE_IMAGE_QUESTION = 'multiple image question';
const IMAGE = 'image';
const SIGNATURE_QUESTION = 'signature question';
const BARCODE_QUESTION = 'barcode question';
const RESUME_PAGE = 'resume page';
const PRICE = 'price';

export {
  WELCOME_PAGE,
  LONG_ANSWER,
  SHORT_ANSWER,
  DROP_DOWN_MENU,
  SIMPLE_SELECTION,
  SATISFACTION,
  SELECT_PAGE,
  MULTIPLE_SELECTION,
  NUMERIC_ANSWER,
  END_PAGE,
  YES_NO_QUESTION,
  DATE,
  CONTENT,
  NPS_QUESTION,
  MULTIPLE_IMAGE_QUESTION,
  SIGNATURE_QUESTION,
  BARCODE_QUESTION,
  IMAGE,
  RESUME_PAGE,
  DECIMAL_ANSWER,
  PRICE,
};
