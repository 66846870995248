import {
  SIMPLE_SELECTION, MULTIPLE_SELECTION,
  IMAGE, MULTIPLE_IMAGE_QUESTION,
} from './const/pageTypes';

import {
  ENTRENAMIENTO,
} from './const/formTypes';

const questionValidator = (question, type) => {
  if (!question.title) return 1;
  if (question.type === IMAGE
    && question.requiresTeleaudit
    && question.binaryKpis.length < 1) {
    return 2;
  }
  if (question.type === MULTIPLE_IMAGE_QUESTION
    && question.requiresTeleaudit
    && question.shareKpi.teleauditType === 'none'
    && question.binaryKpis < 1) {
    return 2;
  }
  if ([SIMPLE_SELECTION, MULTIPLE_SELECTION].includes(question.type) && !question.questionChoices) {
    if (!question.questionChoices || question.questionChoices.length < 1) {
      return 3;
    }
  }
  if ([SIMPLE_SELECTION, MULTIPLE_SELECTION].includes(question.type)) {
    if (type === ENTRENAMIENTO && !question.questionAnswer) {
      return 4;
    }
    if (type === ENTRENAMIENTO
      && question.questionAnswer.length <= 1
      && question.questionAnswer[0] === '') {
      return 4;
    }
  }
  return 0;
};

export default questionValidator;
