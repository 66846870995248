import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, IconButton, TextField, Tooltip,
  List, ListItem, ListItemText, Grid,
} from '@material-ui/core';
// icons

import DeleteIcon from '@material-ui/icons/Close';

import { EDIT_MODE, VIEW_MODE } from '../../../utils/const/surveyModes';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import ImageSettingsModal from '../ImageSettingsModal';
import EmailsBackup from '../../EmailsBackup';

function MultipleImageQuestion({
  identifier, pages, info, setUpdate, handleDeleteQuestion,
  mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});
  const [openSettings, setOpenSettings] = useState(false);
  const [kpiDetails, setKpiDetails] = useState([]);
  const [requiresTeleaudit, setRequiresTeleaudit] = useState(false);
  const [shareOfShelfType, setShareOfShelfType] = useState('none');
  const [target, setTarget] = useState(0);
  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      setRequiresTeleaudit(info.requiresTeleaudit);
      if (info.TeleauditKpis && info.requiresTeleaudit) {
        const kpiDetailsArray = [];
        let count = 0;
        info.TeleauditKpis.forEach((teleauditKpi) => {
          if (teleauditKpi.KpiDetail.teleauditType === 'binary') {
            count += 1;
            kpiDetailsArray.push({
              title: teleauditKpi.KpiDetail.name,
              description: teleauditKpi.KpiDetail.description,
              teleauditType: teleauditKpi.KpiDetail.teleauditType,
              id: count,
            });
          } else {
            setShareOfShelfType(teleauditKpi.KpiDetail.teleauditType);
            setTarget(teleauditKpi.KpiDetail.target);
          }
        });
        setKpiDetails(kpiDetailsArray);
      }
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
    } else if (pages) {
      setRequiresTeleaudit(pages[identifier - 1].requiresTeleaudit);
      if (pages[identifier - 1].binaryKpis && pages[identifier - 1].requiresTeleaudit) {
        const kpiDetailsArray = [];
        let count = 0;
        pages[identifier - 1].binaryKpis.forEach((binaryKpi) => {
          count += 1;
          kpiDetailsArray.push({
            title: binaryKpi.title,
            description: binaryKpi.description,
            teleauditType: binaryKpi.teleauditType,
            id: count,
          });
        });
        setKpiDetails(kpiDetailsArray);
        if (pages[identifier - 1].shareKpi.teleauditType !== 'none') {
          setShareOfShelfType(pages[identifier - 1].shareKpi.teleauditType);
          setTarget(pages[identifier - 1].shareKpi.target);
        }
      }
    } else {
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [id]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  const handleKpiSave = () => {
    const shareKpi = {
      teleauditType: shareOfShelfType,
      target: Number(target),
    };
    const binaryKpis = [];
    // eslint-disable-next-line array-callback-return
    kpiDetails.map((item) => {
      const kpi = {
        title: item.title,
        description: item.description,
        teleauditType: item.teleauditType,
      };
      binaryKpis.push(kpi);
    });
    setUpdate((prevState) => !prevState);
    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      requiresTeleaudit,
      binaryKpis,
      shareKpi,
    };
  };

  return (
    <div className={styles.root}>
      {/* <img src="/TCGHRES2021BACK2MainBlue.png" alt="TCG" /> */}
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.welcomeTitle}
            placeholder={t('survey.surveyEdition.multipleImageText.question')}
            InputProps={{ disableUnderline: true }}
            multiline
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={!!info}
          />
          <TextField
            id="description"
            className={styles.welcomeMessage}
            placeholder={t('survey.surveyEdition.multipleImageText.description')}
            InputProps={{ disableUnderline: true }}
            multiline
            value={info ? info.description : pages[identifier - 1].description}
            onChange={handleOnChange}
            disabled={!!info}
          />
          <Button
            onClick={() => setOpenSettings(true)}
          >
            {t('survey.surveyEdition.multipleImageText.settings')}
          </Button>
          {openSettings && (
            <ImageSettingsModal
              requiresTeleaudit={requiresTeleaudit}
              setRequiresTeleaudit={setRequiresTeleaudit}
              kpiDetails={kpiDetails}
              setKpiDetails={setKpiDetails}
              setOpenSettings={setOpenSettings}
              openSettings={openSettings}
              questionImageType="multiple"
              shareOfShelfType={shareOfShelfType}
              setShareOfShelfType={setShareOfShelfType}
              setTarget={setTarget}
              target={target}
              handleKpiSave={handleKpiSave}
              mode={mode}
            />
          )}
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('survey.surveyForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('survey.surveyForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default MultipleImageQuestion;
