import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  Paper,
} from '@material-ui/core';

// components
import Snackbar from '../../components/Snackbar';
import Button from '../../components/Button';

import SurveysTable from './table';
import CreateSurveyEditView from './CreateSurvey/editView';
import SurveyDetail from './surveyDetail';
import CreateSurvey from './CreateSurvey';
import DuplicateSurvey from './DuplicateSurvey';
import EditSurvey from './EditSurvey';

// requests
import { getSurvey } from '../../requests/api/survey';

import useStyles from './styles';

function Surveys() {
  const styles = useStyles();
  const { t } = useTranslation();

  // snackbar
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [open, setOpen] = useState(false);
  const [editView, setEditView] = useState(false);
  const [editSurvey, setEditSurvey] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: '',
    type: { name: '', value: '' },
    project: { name: '', value: '' },
  });
  const [title, setTitle] = useState(null);
  const [surveyId, setSurveyId] = useState(null);

  const [surveyInfo, setSurveyInfo] = useState({
    project: '',
    title: '',
    type: '',
    requiredGeoreference: false,
    revisionPolicy: '',
  });

  const [verticalForm, setVerticalForm] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [duplicateSurvey, setDuplicateSurvey] = useState(false);

  const tableHeaders = [
    {
      id: 'name',
      label: t('survey.columnNames.title'),
    },
    {
      id: 'type',
      label: t('survey.columnNames.type'),
    },
    {
      id: 'taskAssigned',
      label: t('survey.columnNames.taskAssigned'),
    },
    {
      id: 'actions',
      label: t('survey.columnNames.actions'),
    },
  ];

  const [values, setValues] = useState({
    isSwitchStock: false,
    productInformation: false,
  });
  const [switchStock, setSwitchStock] = useState(null);

  useEffect(() => {
    const getSurveyById = async () => {
      const { data, success } = await getSurvey(surveyId);
      if (success) {
        setSurveyInfo({
          title: data.data.survey.title,
          project: data.data.survey.projectId,
          type: { name: data.data.survey.type },
          requiredGeoreference: data.data.survey.requiredGeoreference,
          revisionPolicy: data.data.survey.revisionPolicy,
        });
        setVerticalForm(data.data.survey.vertical);
        setTitle(data.data.survey.title);
        setQuestions(data.data.survey.Questions);
      }
    };

    if (surveyId) {
      getSurveyById();
    }
  }, [surveyId]);
  return (
  <>
    {editView && !surveyId ? (
      <div className={styles.content}>
        <CreateSurveyEditView
          inputValues={inputValues}
          project={inputValues.project}
          vertical={verticalForm}
          formType={inputValues.type}
          setEditView={setEditView}
          setModalOpen={setOpen}
          setInputValues={setInputValues}
          valuesProp={values}
          setValuesProp={setValues}
          switchStock={switchStock}
          setSwitchStock={setSwitchStock}
        />
      </div>
    ) : null}
    {!editView && !surveyId && !duplicateSurvey ? (
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant='h4' className={styles.title}>{t('survey.title')}</Typography>
          <Button
            id="save-btn"
            variant="outlined"
            className={styles.createButton}
            text={t('survey.createButton')}
            height="40px"
            width="220px"
            backgroundColor='theme-secondary-blue'
            color='white'
            borderRadius='10px'
            onClick={() => setOpen(true)}
          />
        </div>
        <Paper className={styles.surveyTable}>
          <SurveysTable
            setSurveyId={setSurveyId}
            setEditSurvey={setEditSurvey}
            setSeverity={setSeverity}
            setMessage={setMessage}
            tableHeaders={tableHeaders}
            setOpen={setOpen}
            allowCheckIn
            edit={false}
            readOnly
          />
          <CreateSurvey
            defaultOpen={open}
            setCurrentOpen={setOpen}
            setEditView={setEditView}
            inputValues={inputValues}
            setInputValues={setInputValues}
            surveyInfo={surveyInfo}
          />
          <div>
            <Snackbar
              open={message !== ''}
              message={message}
              severity={severity}
              onClose={() => setMessage('')}
            />
          </div>
        </Paper>
      </div>
    ) : null}
    {surveyId && !editSurvey ? (
      <div className={styles.content}>
        <SurveyDetail
          questions={questions}
          project={surveyInfo.project}
          title={surveyInfo.title}
          formType={surveyInfo.type}
          setSurveyId={setSurveyId}
          setSurveyInfo={setSurveyInfo}
          setQuestions={setQuestions}
          setDuplicateSurvey={setDuplicateSurvey}
          setEditSurvey={setEditSurvey}
          defaultOpen={open}
          setCurrentOpen={setOpen}
          setEditView={setEditView}
          inputValues={inputValues}
          setInputValues={setInputValues}
          surveyInfo={surveyInfo}
        />
      </div>
    ) : null}
    {surveyId && editSurvey ? (
      <div className={styles.content}>
        <EditSurvey
          title={title}
          setTitle={setTitle}
          project={inputValues.project}
          formType={inputValues.type}
          setEditView={setEditView}
          setModalOpen={setOpen}
          setInputValues={setInputValues}
          questions={questions}
          setQuestions={setQuestions}
          setSurveyInfo={setSurveyInfo}
          surveyId={surveyId}
          setSurveyId={setSurveyId}
          setEditSurvey={setEditSurvey}
          surveyInfo={surveyInfo}
          vertical={verticalForm}
        />
      </div>
    ) : null}
    {duplicateSurvey ? (
      <div className={styles.content}>
        <DuplicateSurvey
          questions={questions}
          project={surveyInfo.project}
          setModalOpen={setOpen}
          title={surveyInfo.title}
          formType={surveyInfo.type}
          setSurveyInfo={setSurveyInfo}
          setEditView={setEditView}
          setQuestions={setQuestions}
          surveyInfo={surveyInfo}
          setDuplicateSurvey={setDuplicateSurvey}
          setInputValues={setInputValues}
          inputValues={inputValues}
          setCurrentOpen={setOpen}
          vertical={surveyInfo.vertical}
          valuesProp={values}
          setValuesProp={setValues}
          switchStock={switchStock}
          setSwitchStock={setSwitchStock}
        />
      </div>
    )
      : null}

  </>
  );
}

export default Surveys;
