import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  IconButton, TextField, Button,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';

import { EDIT_MODE, VIEW_MODE } from '../../../utils/const/surveyModes';

import EmailsBackup from '../../EmailsBackup';

import useStyles from '../styles';

function ResumePage({
  identifier, pages, info, setUpdate, handleDeleteQuestion,
  mode,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [image, setImage] = useState({});
  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
    } else {
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    }
  }, []);
  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;
    setUpdate((prevState) => !prevState);

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [id]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  return (
    <div className={styles.root}>
      {/* <img src="/TCGHRES2021BACK2MainBlue.png" alt="TCG" /> */}
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.welcomeTitle}
            placeholder={t('survey.surveyEdition.resumePageText.title')}
            InputProps={{ disableUnderline: true }}
            multiline
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('survey.surveyForm.addEmails')}
              </Button>
            </>
          ) : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default ResumePage;
