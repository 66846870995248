import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@material-ui/core';

import { SUPER_ADMIN } from '../../utils/const/user';
import Changes from '../../components/UploadView/Changes';

import { updateLocationUsersChanges, uploadLocationUsersFile } from '../../requests/api/user-locations';

import useStyles from './styles';

function LocationUsersImportView() {
  const classes = useStyles();
  const { t } = useTranslation();

  const account = useSelector((state) => state.account);
  const [uploadDifference, setUploadDifference] = useState(null);

  if (account.user.role !== SUPER_ADMIN) {
    window.location.href = '/home';
  }

  const tableHeaders = [
    {
      id: 'location',
      label: t('locations.importViews.locationUsers.location'),
    },
    {
      id: 'userName',
      label: t('locations.importViews.locationUsers.user'),
    },
    {
      id: 'userEmail',
      label: t('locations.importViews.locationUsers.email'),
    },
  ];

  const properties = ['Location', 'UserName', 'UserEmail'];

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h3" color="textPrimary">
        {t('locations.importViews.locationUsers.uploadFile')}
      </Typography>
      <Box className={classes.noteContainer}>
        <Typography variant="p" color="textPrimary">
          {t('locations.importViews.locationUsers.fileNote')}
          <br />
          {t('locations.importViews.references')}
        </Typography>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceNew} /> {t('locations.importViews.new')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceEqual} /> {t('locations.importViews.equal')}
        </Box>
        <Box className={classes.referenceBox}>
          <div className={classes.referenceDelete} /> {t('locations.importViews.delete')}
        </Box>
      </Box>
      <Paper className={classes.changesTable}>
        <Changes
          properties={properties}
          templateColumns={['location', 'email']}
          templateName="location-users"
          tableHeaders={tableHeaders}
          uploadDifference={uploadDifference || []}
          setUploadDifference={setUploadDifference}
          updateFunction={updateLocationUsersChanges}
          uploadFunction={uploadLocationUsersFile}
        />
      </Paper>
    </div>
  );
}

export default LocationUsersImportView;
