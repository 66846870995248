import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, IconButton, TextField, Tooltip,
  Grid, List, ListItem, ListItemText,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';

import {
  EDIT_MODE,
  VIEW_MODE,
} from '../../../utils/const/surveyModes';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

function ShortAnswer({
  identifier, pages, info, setUpdate,
  mode, handleDeleteQuestion,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});

  useEffect(() => {
    if (info !== undefined) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
    } else {
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    }
  }, []);

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;
    setUpdate((prevState) => !prevState);

    // eslint-disable-next-line no-param-reassign
    pages[identifier - 1] = {
      ...pages[identifier - 1],
      [id]: value,
      questionChoices: null,
      questionLogic: [{
        questionIdentifier: identifier,
        questionChoiceIdentifier: null,
        nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
      }],
      imageUrl,
    };
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t(
              'survey.surveyEdition.shortAnswerText.question',
            )}
            value={info ? info.title : pages[identifier - 1].title}
            InputProps={{ disableUnderline: true }}
            multiline
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          <TextField
            id="description"
            // className={`${styles.description} ${styles.textfield}`}
            className={styles.welcomeMessage}
            placeholder={t(
              'survey.surveyEdition.shortAnswerText.description',
            )}
            value={info ? info.description : pages[identifier - 1].description}
            InputProps={{ disableUnderline: true }}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            disabled={mode === VIEW_MODE}
          />
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('survey.surveyForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('survey.surveyForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <IconButton
          id={identifier}
          onClick={handleDeleteQuestion}
          className={styles.deleteButton}
        >
          <DeleteIcon />
        </IconButton>)
        : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        disabled={!!info}
      />
    </div>
  );
}

export default ShortAnswer;
