import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axiosOriginal from 'axios';
import { useDropzone } from 'react-dropzone';
import {
  CircularProgress,
  Fade, IconButton, Link, List, ListItem, ListItemText, Modal, Paper, Tooltip,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Delete';

import Button from '../Button';
import bytesToSize from '../../utils/bytesToSize';

import useStyles from './styles';

function ExampleImageQuestion({
  open,
  setOpen,
  file,
  setFile,
  handleOnChange,
  setImage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRemoveMedia = () => {
    setFile({});
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const handleUpload = async () => {
    setLoading(true);
    try {
      const fileName = file.name.split('.')[0];
      const uploadData = {
        fileName,
        fileType: file.type,
      };
      const response = await axiosOriginal.post('/api/surveys/signS3', uploadData);
      const options = {
        headers: {
          'Content-Type': file.type,
        },
      };

      const { returnData } = response.data.data;
      const { signedRequest } = returnData;
      const uploadResponse = await axiosOriginal.put(
        signedRequest,
        file,
        options,
      );
      if (uploadResponse.status === 200) {
        const fileUrl = uploadResponse.config.url.split('?Content-Type')[0];
        const event = {
          target: {
            id: 'imageKey',
            value: fileName,
            imageUrl: fileUrl,
          },
        };
        await setImage({
          url: fileUrl,
          name: file.name,
          size: bytesToSize(file.size),
        });
        handleOnChange(event);
        setError('');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError(t('fileDropzone.errorUpload'));
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.root}
    >
      <Fade in={open}>
          <Paper className={classes.paperContainer}>
              <Tooltip title={t('survey.surveyForm.deleteMedia')}>
                <IconButton
                  className={classes.deleteButton}
                  onClick={handleRemoveMedia}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <p>{t('survey.surveyForm.addMediaTitle')}</p>
              <div className={classes.dragAndDropContainer}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className={classes.dragAndDropZone}>
                      <p className={classes.dropText}>
                          {t('survey.surveyForm.addImageExampleParagraphOne')}
                          {' '}
                          <Link underline="always">{t('survey.surveyForm.search')}</Link>
                          {' '}
                          {t('survey.surveyForm.addImageExampleParagraphTwo')}
                      </p>
                    </div>
                  </div>
                  {file.name && (
                    <>
                      <List className={classes.list}>
                        <ListItem>
                          <ListItemText
                            primary={file.name}
                            primaryTypographyProps={{ variant: 'h5' }}
                            secondary={bytesToSize(file.size)}
                          />
                        </ListItem>
                      </List>
                      <Button
                        id="submit-ticket-btn"
                        onClick={handleUpload}
                        text={loading ? <CircularProgress /> : t('fileDropzone.upload')}
                        height="40px"
                        width="130px"
                        backgroundColor='theme-secondary-blue'
                        color='theme-primary-white'
                        borderRadius='10px'
                      />
                    </>
                  )}
                  {error ? (
                      <p className={classes.errorMessage}>{error}</p>
                  ) : null}
              </div>
          </Paper>
      </Fade>
    </Modal>
  );
}

ExampleImageQuestion.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
};

export default ExampleImageQuestion;
